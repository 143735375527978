.side-bar {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    border-right: 1px solid;
    border-radius: 0;
    border-color: #ff9ab0;
    background-color: rgb(255, 255, 255);
    transition: 0.8s ease;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    right: -0%;
}

.toggle-menu {
    height: 50px;
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 9rem;
    width: 20px;
    position: absolute;
    outline: none;
    z-index: 1;
    background-color: #ff9ab0;
    border-color: white;
    border-left: 0;
}

.icon-sidebar
{
    font-size: small;
    color: white;
}
.cart-header
{
    text-align: center;
}